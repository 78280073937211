import "./App.scss";
import React, { useState } from "react";

import html2canvas from "html2canvas";

import Grid from "./Grid";
import Categories from "./Categories";

function App() {
	/* Type of Category */
	const [currentType, setCurrentType] = useState("scale_0");
	const [currentColor, setCurrentColor] = useState("#ffb1b4");
	let types_alt = [
		{ type: "좋음", color: "#ffb1b4", class: "scale_0" },
		{ type: "관심 있음", color: "#fcd5bc", class: "scale_1" },
		{ type: "내가 하진 않지만 2차 ok", color: "#fef3b3", class: "scale_2" },
		{ type: "탐라에 들어오면 봄", color: "#c1fdb4", class: "scale_3" },
		{ type: "준비되어 있음", color: "#afe3fe", class: "scale_4" },
		{ type: "아직 생각 안해봄", color: "#c1c1c1", class: "scale_5" },
		{ type: "캐해를 가림", color: "#dab4ff", class: "scale_6" },
		{ type: "친구가 하는 건 좋아함", color: "#fdb2ff", class: "scale_7" },
		{ type: "지뢰", color: "rgb(50, 50, 50)", class: "scale_8" },
	];

	let types = [
		{ type: "제일 좋아하고 언급 많이 함", color: "#ffb1b4", class: "scale_0" },
		{ type: "주력은 아닌데 좋아하고 수제트윗도 씀", color: "#fcd5bc", class: "scale_1" },
		{ type: "소비 위주", color: "#fef3b3", class: "scale_2" },
		{ type: "논페스적 관계성을 좋아함", color: "#c1fdb4", class: "scale_3" },
		{ type: "언급은 안하지만 진짜. 라고 생각함", color: "#afe3fe", class: "scale_4" },
		{ type: "수련중", color: "#dab4ff", class: "scale_6" },
		{ type: "아직 생각 안해봤는데 일단 줘보세요", color: "#fdb2ff", class: "scale_7" },
		{ type: "본다고 죽지는 않는데 별로 안 보고싶음", color: "#c1c1c1", class: "scale_5" },
		{ type: "보면 죽음", color: "rgb(50, 50, 50)", class: "scale_8" },
	];

	let types_3 = [
		{ type: "정말이지 너무 좋음 사랑함 하..", color: "#ffb1b4", class: "scale_0" },
		{ type: "흥미롭게 지켜봄 좋음!! 굿", color: "#fcd5bc", class: "scale_1" },
		{ type: "2차가 재밌다면 재밌게 봄", color: "#fef3b3", class: "scale_2" },
		{ type: "어쩐지 1차만 보고 있음", color: "#c1fdb4", class: "scale_3" },
		{ type: "논페스적 관계성에 주목함", color: "#afe3fe", class: "scale_4" },
		{ type: "적극적으로 하진 않지만 굿", color: "#dab4ff", class: "scale_6" },
		{ type: "굳이 보고 싶진 않음", color: "#c1c1c1", class: "scale_5" },
		{ type: "조합이 싫은 건 아니고 캐해가 괴로움", color: "rgb(50, 50, 50)", class: "scale_8" },
	];

	const [typeSetStatus, setTypeSetStatus] = useState(true);
	const [typeSet, setTypeSet] = useState(types);

	let selectType = (type) => {
		setCurrentType(type.class);
		setCurrentColor(type.color);
	};

	let categories = [];

	let categoryShorthand = [];
	for (let i = 0; i < typeSet.length; i++) {
		categories.push(
			<Categories.Category
				isSelected={currentType === typeSet[i].class ? true : false}
				class={typeSet[i].class}
				type={typeSet[i].type}
				color={typeSet[i].color}
				onClick={() => {
					selectType(typeSet[i]);
				}}
				key={typeSet[i].class}
			/>
		);

		categoryShorthand.push(
			<Categories.CategoryShorthand
				class={typeSet[i].class}
				type={typeSet[i].type}
				color={typeSet[i].color}
				onClick={() => {
					selectType(typeSet[i]);
				}}
				key={typeSet[i].class}
			/>
		);
	}

	/* Downloadable Image generator */
	const ref = React.createRef();

	let downloadable = new Image();
	const [finished, setFinished] = useState(false);

	let finishGeneration = () => {
		setFinished(true);
		download();
	};

	let download = () => {
		const element = ref.current;

		html2canvas(element).then((canvas) => {
			downloadable.src = canvas.toDataURL("image/png");
		});
		document.querySelector(".App").append(downloadable);
	};

	return (
		<div className="App" style={finished ? { padding: "30px", width: "100%" } : {}}>
			{finished ? (
				<div id="final-wrapper">
					<div>이미지를 마우스 우클릭하거나 꾹 눌러서 다운로드하세요.</div>
					<div
						className="buttons"
						onClick={() => {
							window.location.reload();
						}}>
						다시 만들기 🔄
					</div>
				</div>
			) : (
				<div>
					<div id="categories-wrapper">
						<div
							className="buttons"
							onClick={() => {
								if (typeSetStatus) {
									setTypeSet(types_alt);
								} else {
									setTypeSet(types);
								}
								setTypeSetStatus(!typeSetStatus);
							}}>
							취향표 모드 바꾸기
						</div>
						<div id="categories">{categories}</div>
					</div>
					{/* <div id="grid-wrapper">
				<div class="title">셉페스 취향표</div>
				<div id="main-grid">{this.currentList}</div>
			</div> */}
					<div id="capture-wrapper">
						<div id="capture-area" ref={ref}>
							<Grid currentColor={currentColor} />

							<div id="category-display">{categoryShorthand}</div>
						</div>
					</div>
					<div
						id="finishbutton"
						className="buttons"
						onClick={() => {
							finishGeneration();
						}}>
						이미지 만들기 📸
					</div>
				</div>
			)}
			<div id="credit" style={{ textAlign: "center" }}>
				만든 사람: 교수 (<a href="https://twitter.com/phdminorstudies">@phdminorstudies</a>)<br />
				버그 제보, 기타 요청 등은 위 계정으로 해 주세요😎
			</div>
		</div>
	);
}

export default App;
