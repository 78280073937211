import React, { useState } from "react";

const Cell = (props) => {
	const [currentColor, setCurrentColor] = useState("white");

	let changeColor = () => {
		if (props.code === currentColor) {
			setCurrentColor("white");
		} else {
			setCurrentColor(props.code);
		}
	};

	return (
		<div
			className={props.isHeader ? "cell header" : "cell"}
			style={{ backgroundColor: currentColor }}
			onClick={() => {
				changeColor();
			}}>
			{props.name}
		</div>
	);
};

export default Cell;
