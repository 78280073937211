import React from "react";
import Cell from "./Cell";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faRotateRight } from "@fortawesome/free-solid-svg-icons";

class Grid extends React.Component {
	state = { value: false };

	/* Setup Lists */
	MEMBERLIST = [
		"최승철",
		"윤정한",
		"홍지수",
		"문준휘",
		"권순영",
		"전원우",
		"이지훈",
		"서명호",
		"김민규",
		"이석민",
		"부승관",
		"최한솔",
		"이찬",
	];
	currentList;

	initialize = () => {
		let cpList = [<Cell name={""} row={0} col={0} key={0} />];
		for (let i = 0; i < this.MEMBERLIST.length; i++) {
			cpList.push(
				<Cell name={this.MEMBERLIST[i]} row={i + 1} col={0} isHeader={true} key={i + 1} />
			);
		}

		for (let i = 1; i < this.MEMBERLIST.length + 1; i++) {
			let j = 1;
			cpList.push(
				<Cell name={this.MEMBERLIST[i - 1]} row={j - 1} col={i} isHeader={true} key={-i} />
			);
			for (; j < this.MEMBERLIST.length + 1; j++) {
				let couple =
					this.MEMBERLIST[j - 1].substring(1) + "×" + this.MEMBERLIST[i - 1].substring(1);
				cpList.push(
					<Cell
						name={couple}
						row={j - 1}
						col={i - 1}
						key={i + "x" + j}
						code={this.props.currentColor}
					/>
				);
			}
		}

		this.currentList = cpList;
	};

	resetView = () => {};

	render() {
		this.initialize();
		return (
			<div id="grid-wrapper">
				<div className="title">셉페스 취향표</div>
				<div id="main-grid">{this.currentList}</div>
			</div>
		);
	}
}
export default Grid;
