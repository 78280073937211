import React from "react";

const Category = (props) => {
	return (
		<div
			className={"category " + props.class + " " + props.isSelected}
			onClick={() => {
				props.onClick();
			}}>
			{props.type}
		</div>
	);
};

const CategoryShorthand = (props) => {
	return (
		<div className="category-shorthand">
			<div className="category-cc" style={{ backgroundColor: props.color }}></div>
			<div className="category-text">{props.type}</div>
		</div>
	);
};

const Categories = { Category, CategoryShorthand };

export default Categories;
